import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './components/Index';
import TrainerTasks from './components/TrainerTasks'
import Trainer from './components/Trainer';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Sandbox from './components/Sandbox';
import Handbook from './components/Handbook';
import Course from './components/Course';
import CourseChapter from './components/CourseChapter';
import HandbookFunction from './components/HandbookFunction';
import Imprint from './components/Imprint';
import Privacy from './components/Privacy';
import AITutor from './components/AITutor';
import VoiceAssistant from './components/AITutorLive';
import Cookies from './components/Cookies';
import Module from './components/Module';
import PaymentResult from './components/PaymentResult';
import { SessionProvider } from './components/SessionContext';

function App() {
  return (
    <SessionProvider>
    <Navigation />
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/trainer/tasks/:id" element={<TrainerTasks />} />
        <Route path="/trainer/" element={<Trainer />} />
        <Route path="/sandbox/" element={<Sandbox />} />
        <Route path="/handbook/" element={<Handbook />} />
        <Route path="/handbook/:id" element={<HandbookFunction />} />
        <Route path="/ai-tutor/" element={<VoiceAssistant />} />
        <Route path="/course/" element={<Course nItems={undefined} />} />
        <Route path="/course/:moduleId/:parentChapterId/:chapterId" element={<CourseChapter />} />
        <Route path="/module/:moduleId" element={<Module />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/payment" element={<PaymentResult />} />
      </Routes>
    </Router>
    <Cookies />
    <Footer />
    </ SessionProvider>
  );
}

export default App;
