import React from 'react';
import { Helmet } from 'react-helmet';

const Privacy = () => {
  return (
    <div className="container">
        <Helmet>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <h1>Privacy Policy</h1>
        <div class="section">
            <p>Last updated: 02.12.2024</p>

            <h2>1. Introduction</h2>
            <p>This Privacy Policy explains how sql-expert.org ("we," "us," or "our") collects, uses, and protects your information when you visit our website.</p>
        </div>

        <div class="section">
            <h2>2. Data Collection</h2>
            <p>We only collect analytics data through Google Analytics, and this only occurs with your explicit consent. We do not collect any personal information beyond what Google Analytics provides.</p>
        </div>

        <div class="section">
            <h2>3. Google Analytics</h2>
            <p>We use Google Analytics to understand how visitors use our website. This service may collect information such as:</p>
            <ul>
                <li>Pages visited</li>
                <li>Time spent on pages</li>
                <li>Browser type</li>
                <li>Device type</li>
                <li>Approximate geographic location (country/city level)</li>
                <li>Referral source</li>
            </ul>
                <p>This information is collected anonymously and is not linked to any personally identifiable information.</p>
            </div>

            <div class="section">
                <h2>4. Cookie Usage</h2>
                <p>We only use necessary cookies for Google Analytics functionality, and these are only set after you provide consent. You can withdraw your consent at any time by adjusting your cookie preferences in our cookie banner.</p>
            </div>

            <div class="section">
                <h2>5. Data Processing</h2>
                <p>The analytics data collected is processed by Google Analytics in accordance with their privacy policy. We do not combine this data with other sources and do not use it for any purpose other than improving our website and understanding user behavior.</p>
            </div>

            <div class="section">
                <h2>6. Your Rights</h2>
                <p>You have the right to:</p>
                <ul>
                    <li>Refuse analytics cookies</li>
                    <li>Withdraw your consent at any time</li>
                    <li>Request information about the data collected about you</li>
                    <li>Request deletion of your data</li>
                </ul>
            </div>

            <div class="section">
                <h2>7. Data Protection</h2>
                <p>We take appropriate measures to ensure that any data collected through Google Analytics is processed securely and in accordance with this privacy policy.</p>
            </div>

            <div class="section">
                <h2>8. Contact Information</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                <p>Email: contact@perevalov.com</p>
            </div>

            <div class="section">
                <h2>9. Changes to This Policy</h2>
                <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>
            </div>
        </div>
  );
}

export default Privacy;
