import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { emojiBlast } from "emoji-blast";

const PaymentResult = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSuccess = queryParams.get('success') === 'true';
  const isCanceled = queryParams.get('canceled') === 'true';

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        emojiBlast();
      }, 750);
      
    }
  }, [isSuccess]);

  return (
    <div className='container text-center' style={{ marginBottom: '45vh' }}>
      {isSuccess && (
        <div >
          <h2>🎉 Payment was successful!</h2>
          <h4>You will receive a confirmation email shortly.</h4>
        </div>
      )}
      {isCanceled && (
        <div>
          <h2>❌ Payment was canceled.</h2>
          <h4>If you have any questions, please contact us in Discord.</h4>
        </div>
      )}
    </div>
  );
};

export default PaymentResult;
