import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  Avatar,
} from '@mui/material';
import { API_URL } from '../Config';

const teamMembers = [
  {
    name: 'Vladislav Raskoshinskii',
    role: 'Senior Data Scientist',
    description:
      'Vladislav is a data scientist with strong data engineering skills. He is passionate about machine learning and AI.',
    avatar: '/static/vladislav-raskoshinskii.jpg', // Replace with actual image path
    priceId: 'price_1Qtn7H083JvxHLVq8JRw6M8W'
  },
  {
    name: 'Aleksandr Perevalov',
    role: 'PhD researcher in Conversational AI',
    description:
      'Aleksandr is a PhD researcher in Conversational AI. He is passionate about natural language processing and chatbots.',
    avatar: '/static/aleksandr-perevalov.jpg', // Replace with actual image path
    priceId: 'price_1QtnBV083JvxHLVq1Rwzc9NK'
  },
];

const TeamSection = () => {
  return (
    <Box
      sx={{
        py: 2
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',

                  p: 3,
                  transition: 'transform 0.3s ease-out',
                  '&:hover': {
                    transform: 'translateY(-5px)'
                  }
                }}
                elevation={2}
              >
                <Avatar
                  src={member.avatar}
                  alt={member.name}
                  sx={{
                    width: 150,
                    height: 150,
                    mb: 2,
                    border: '4px solid #fff',
                    boxShadow: '0 0 15px rgba(0,0,0,0.1)'
                  }}
                />
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="h5"
                    component="h3"
                    gutterBottom
                    sx={{ fontWeight: 'bold' }}
                  >
                    {member.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    gutterBottom
                    sx={{ mb: 2 }}
                  >
                    {member.role}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {member.description}
                  </Typography>
                  <Typography>
                    <form action={`${API_URL}/payments/create-checkout-session-consultation`} method="POST">
                      <input type="hidden" name="priceId" value={member.priceId} />
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        size="small"
                        sx={{ mt: 2 }}
                      >
                        Book a premium session
                      </Button>
                    </form>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
};

export default TeamSection;