import React from 'react';
import Split from 'react-split'
import EditorComponent from './Editor';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { emojiBlast } from "emoji-blast";
import { API_URL } from '../Config';

import '../assets/css/trainerTasks.css'
import "../assets/css/resizable.css";
import { Helmet } from 'react-helmet';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TrainerTasks = () => {
    let { id } = useParams();
    const [db, setDb] = React.useState('air_trips_db');
    let [task, setTask] = useState(null);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [isCorrect, setIsCorrect] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
      // Check if the task is already done
      const doneTasks = JSON.parse(localStorage.getItem('doneTasks')) || [];
      setIsDone(doneTasks.includes(id));

      const fetchFunction = async () => {
        const response = await fetch(API_URL + '/tasks/' + id);
        const data = await response.json();
        setTask(data[0]);
        setDb(data[0].db_name);
        console.log(data)
      };
      fetchFunction();
    }, [id]);

    async function markAsDone() {
      let doneTasks = JSON.parse(localStorage.getItem('doneTasks')) || [];
      if (!doneTasks.includes(id)) {
        doneTasks.push(id);
          localStorage.setItem('doneTasks', JSON.stringify(doneTasks));
          setIsDone(true);
      }
  }
  const getDifficultyClass = (diffClass) => {
    console.log(diffClass);
    if (diffClass === 'Easy') {
        return 'i-easy'
    }
    if (diffClass === 'Medium') {
        return 'i-middle'
    }
    if (diffClass === 'Hard') {
        return 'i-hard'
    }
  }

    const runHandler = (e) => {
      if (!isDone) {
        localStorage.setItem('task_' + id, e.target.value);
      }

      const generateData = async () => {
        const response = await fetch(API_URL + '/dbs/' + db + '/execute', {
          method: 'POST',
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({"query": e.target.value})
        });
        setPage(0);
        const data = await response.json();
        
        setAlertSeverity('info');
        setAlertContent('BAANG! 😁🎓');
        setIsSnackbarOpen(true);
        
        if ("error" in data) {
          setRows([{ error: data.error }]);
          setColumns([{ id: 'error', label: 'Error', minWidth: 100 }]);
          return;
        }
        console.log(data);
        if (data.rows.length === 0) {
          return;
        }
        setRows(data.rows);
        const firstRow = data.rows[0];
        const columnsList = Object.keys(firstRow).map(key => ({ id: key, label: key, minWidth: 100 }));
        console.log(columnsList);
        setColumns(columnsList);
      };
      generateData();
    };

    const validateHandler = (e) => {
      const validateData = async () => {
        const responseRows = await fetch(API_URL + '/dbs/' + db + '/execute', {
          method: 'POST',
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({"query": e.target.value})
        });
        setPage(0);

        const executeData = await responseRows.json() 
        console.log(executeData);
        if ("error" in executeData) {
          setRows([{ error: executeData.error }]);
          setColumns([{ id: 'error', label: 'Error', minWidth: 100 }]);
          return;
        }

        const result = executeData.rows;

        if (result.length === 0) {
          setAlertSeverity('error');
          setAlertContent('Check your query! No rows returned 🥲');
          setIsSnackbarOpen(true);

          setIsCorrect(false);
          if (!isDone) {
            localStorage.setItem('task_' + id, e.target.value);
          }
          return;
        }

        setRows(result);
        const firstRow = result[0];
        const columnsList = Object.keys(firstRow).map(key => ({ id: key, label: key, minWidth: 100 }));
        setColumns(columnsList);


        const response = await fetch(API_URL + '/tasks/' + id + '/validate', {
          method: 'POST',
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({"result": result})
        });
        const data = await response.json();
        //alert(JSON.stringify(data));
        if ("OK" in data) {
          setAlertSeverity('success');
          setAlertContent('Done! 🎉 What a success ✅');
          setIsCorrect(true);
          markAsDone();
          
          localStorage.setItem('task_' + id, e.target.value);

          setTimeout(() => {
            emojiBlast();
          }, 750);
        } else {
          setAlertSeverity('error');
          setAlertContent('Check your query. ' + data.error + ' 🥲');
          setIsCorrect(false);
          if (!isDone) {
            localStorage.setItem('task_' + id, e.target.value);
          }
        }
        setIsSnackbarOpen(true);
      };
      validateData();
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const openTask = (taskId) => {
      if (taskId < 1) {
        taskId = 60; // TODO: get the number of tasks from the API
      }
      if (taskId > 60) {
        taskId = 1; // TODO: get the number of tasks from the API
      }
      window.location.href = taskId;
    }
  return (

    <div>
      <Helmet>
          <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        autoHideDuration={1500}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert severity={alertSeverity} sx={{ boxShadow: 4 }}> 
          {alertContent}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Database schema
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          
          </Typography>
        </Box>
      </Modal>
      <Split
          className="wrap"
          sizes={[35, 65]}
          minSize={200}
          expandToMin={false}
          gutterSize={5}
          gutterAlign="center"
          snapOffset={10}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
      >  
        <div className="container bg-light">
          <h4 className='mt-2'>🏋️‍♂️ Exercise {task?.number}{isDone ? ' ✅' : ''}</h4>
          <aside dangerouslySetInnerHTML={{__html: `<i class="fas fa-circle ${getDifficultyClass(task?.difficulty)}"></i> ` + task?.description}}></aside>
          {task?.hint && task.hint.length > 0 && (
            <div>
              <h5 className='mt-4'>💡 Hints</h5>
              <aside>
              {task.hint.map((hint, index) => (
                <span key={index} dangerouslySetInnerHTML={{__html: hint}}></span>
              ))}
              </aside>
            </div>
          )}
          <h5>🎯 Expected Result Columns</h5>
          <ul>
          {task?.result_columns.map((column, index) => (
            <li key={index}><code>{column}</code></li>
          ))}
          </ul>
          
          <div className="d-flex justify-content-between mt-3 mb-3">
            <button className="btn btn-light shadow-sm border" onClick={() => openTask(`${parseInt(id) - 1}`)}>⬅️ Previous Task</button>
            <button className="btn btn-light shadow-sm border" onClick={() => openTask(`${parseInt(id) + 1}`)}>Next Task ➡️</button>
          </div>
          <hr></hr>
          <iframe width="100%" height="400px" allowtransparency="true" allowfullscreen="true" scrolling="no" title="Embedded DrawSQL IFrame" frameborder="0" src={"https://drawsql.app/teams/sql-expert-1/diagrams/" + db.replaceAll("_", "-") + "/embed"}></iframe>
      </div>
        <div className='bg-white'>
            <EditorComponent isSandbox={false} runHandler={runHandler} validateHandler={validateHandler} cachedContent={localStorage.getItem('task_' + id) || ''} />
            <hr className='hr hr-blurry mt-0'></hr>
            <div>
              <h5 className="result mt-2" style={{marginLeft: '5px'}}>Query result</h5>
              <TableContainer sx={{
                 maxHeight: 440, 
                 border: rows.length === 0 ? '5px solid rgba(255, 255, 255, 0)' : isCorrect ? '5px solid #45f54275' : '5px solid rgba(66, 188, 245, 0.4)',
                 borderRadius: '15px',
                 transition: 'border-color 0.5s ease-in-out',
                 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, fontWeight: 'bold', fontFamily: 'monospace' }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={column.id} align={column.align} style={{ fontFamily: 'monospace'}}>
                                    {column.format && typeof value === 'number'
                                      ? column.format(value)
                                      : typeof value === 'boolean'
                                      ? value.toString()
                                      : value}
                                  </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
          </div>
        </div>
      </Split>
    </div>
  );
}

export default TrainerTasks;
