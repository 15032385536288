import React, { useState, useEffect } from 'react';
import { Modal, Box } from '@mui/material';
import { API_URL } from '../../Config';

const FeedbackModal = ({ open, handleClose, sessionId }) => {
  const [email, setEmail] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);
  
  
  async function sendAssessmentFeedback(email) {
    try {
      console.log('sessionId:', sessionId);
      const response = await fetch(API_URL + '/email/send-assessment-feedback', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          session: sessionId
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return true;
    } catch (error) {
      console.error('Error sending interview guide:', error);
      throw error;
    }
  }

  useEffect(() => {
    const emailSubscribed = localStorage.getItem('emailSubscribed');
    if (emailSubscribed) {
      setIsFeedbackSent(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data = await sendAssessmentFeedback(email);
      
      if (data) {
        //setMessage('Thank you for subscribing!');
        setEmail('');
        setIsFeedbackSent(true);
        localStorage.setItem('emailSubscribed', true);
        localStorage.setItem('lastTutorConversation', new Date().toISOString());
        handleClose();  
        alert('Thank you, we sent you the feedback!');

      } else {
        alert('Failed to subscribe. Please try again.');
        //setMessage('Failed to subscribe. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="email-modal-title"
      aria-describedby="email-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          borderRadius: 4,
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90vw' : '30vw',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <p className="text-center mt-2 fs-4 fw-bold">Your skills assessment is ready. Get it per e-mail ✅</p>
        <div className='text-center mt-3'>
          <p>🔥 Get personalized feedback on your SQL skills</p>
          <p>🚀 We'll prepare personalized learning recommendations</p>
          <p>💼 Get ready for your next job interview</p>
        </div>
        <div className="input-container">
          <form onSubmit={handleSubmit} className='signup-form'>
            <input
              type="email"
              placeholder="Enter your email"
              className='email-input'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button className="submit-button" type="submit" onClick={handleSubmit}>Get assessment</button>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;