import React, { useState, useEffect } from 'react';
import { API_URL } from '../Config';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import '../assets/css/newsletter.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);


  async function sendInterviewGuide(email) {
    try {
      const response = await fetch(API_URL + '/email/send-interview-guide', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error sending interview guide:', error);
      throw error;
    }
  }

  useEffect(() => {
    const emailSubscribed = localStorage.getItem('emailSubscribed');
    if (emailSubscribed) {
      setIsSubscribed(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data = await sendInterviewGuide(email);
      
      if (data) {
        setMessage('Thank you for subscribing!');
        setEmail('');
        setIsSubscribed(true);
        localStorage.setItem('emailSubscribed', true);
        alert('Success! Please check your email (also spam folder) for the interview guide.');

      } else {
        setMessage('Failed to subscribe. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <>
    {!isSubscribed && (
    <div className="newsletter-container">
      <h2 className="fw-bold text-center mt-5">Pass your next Data Science interview</h2>
      <div className='center'>
      <AvatarGroup max={2} >
        <Avatar alt="Vladislav" src="/static/vladislav-raskoshinskii.jpg"/>
        <Avatar alt="Aleksandr" src="/static/aleksandr-perevalov.jpg"/>
        </AvatarGroup>
      </div>
      <p className="text-center mt-2">Get a <span className='underline-green'>free interview cheatsheet</span> from us</p>
      <div className="input-container">
      <form onSubmit={handleSubmit} className='signup-form'>
        <input
          type="email"
          placeholder="Enter your email"
          className='email-input'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className="submit-button" type="submit">Join our network</button>
        
      </form>
      </div>
    </div>
    )}
    </>
  );
};

export default Newsletter;