import React, { useState, useCallback, useEffect } from "react";
import { useConversation } from "@11labs/react"; // Import useConversation from elevenlabs-react
import { motion } from "framer-motion";
import FeedbackModal from "./modals/FeedbackModal";


const VoiceAssistant = () => {
  const defaultTime = 180; // 3 minutes in seconds
  const [time, setTime] = useState(defaultTime);
  const [isRunning, setIsRunning] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [isModalFeedbackOpen, setIsModalFeedbackOpen] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [lastTutorConversation, setLastTutorConversation] = useState(null);
  const [sessionId, setSessionId] = useState('null');

  const canSpeakWithTheTutor = () => {
    const lastConversation = localStorage.getItem('lastTutorConversation');
    if (lastConversation) {
      const parsedConversation = lastConversation;
      const lastConversationTime = new Date(parsedConversation);
      const currentTime = new Date();
      const timeDifference = (currentTime - lastConversationTime) / (1000 * 60 * 60); // time difference in hours
      if (timeDifference > 24) {
        setLastTutorConversation(null);
        return true
      } else {
        setLastTutorConversation(parsedConversation);
        return false;
      }
    } else {
      return true;
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };
  
  const conversation = useConversation({
    onConnect: () => {
      console.log('Connected')
      setHasStarted(true);
      toggleTimer();
    },
    onDisconnect: () => {
      console.log('Disconnected')
      toggleTimer();
    },
    onMessage: (message) => {
      console.log('Message:', message);
      setTranscript(message.message);
      // message source user ai
      setConversationHistory(prevHistory => [...prevHistory, { role: "assistant", content: message }]);
    },
    onError: (error) => console.error('Error:', error),
  });

  const onCallEnded = () => {
    conversation.endSession();
    setTime(defaultTime);
    setTranscript("Thanks, we'll email you the results shortly.");
    setHasStarted(false);
    setIsModalFeedbackOpen(true);
  };

  useEffect(() => {
    let interval;
    if (isRunning && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      clearInterval(interval);
      onCallEnded();
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, time]);

  const toggleTimer = () => {
    setIsRunning((prev) => !prev);
  };

  const startConversation = useCallback(async () => {
    try {
      if (!canSpeakWithTheTutor()) {
          alert('You have already completed the interview. Please check your email for the results. We allow one AI call per 24 hours.');
          return;
      }
      await navigator.mediaDevices.getUserMedia({ audio: true });
      let sessionString = await conversation.startSession({
        agentId: process.env.REACT_APP_ELEVENLABS_AGENT_ID, // Replace with your agent ID
      });
      setSessionId(sessionString);
      console.log('Session string:', sessionString);
    } catch (error) {
      console.error('Failed to start conversation:', error);
    }
  }, [conversation]);

  const stopConversation = useCallback(async () => {
    await conversation.endSession();
    setHasStarted(false);
  }, [conversation]);

  return (
    <>
    <FeedbackModal open={isModalFeedbackOpen} handleClose={() => setIsModalFeedbackOpen(false)} sessionId={sessionId}/>
    <div className="d-flex flex-column align-items-center" style={{ background: "linear-gradient(to bottom, #ecfbfd, #F0F2F5)", margin: "auto" }}>
      <div className="text-center p-4 rounded mt-5" style={{ background: "rgba(255, 255, 255, 0.1)", backdropFilter: "blur(10px)", maxWidth: "500px", width: "100%", wordBreak: "break-word" }}>
      <motion.img
        src="/static/agent-logo.jpg"
        alt="Tutor Avatar"
        className="rounded-circle"
        style={{
          width: "125px",
          height: "125px",
          border: "5px solid rgb(46, 75, 204)",
          boxShadow: hasStarted ? "0 0 20px rgba(46, 75, 204, 0.5)" : "none"
        }}
        animate={
          conversation.isSpeaking
            ? {
                boxShadow: [
                  "0 0 20px rgba(46, 75, 204, 0.5)",
                  "0 0 40px rgba(46, 75, 204, 1)",
                  "0 0 20px rgba(46, 75, 204, 0.5)"
                ]
              }
            : {}
        }
        transition={{
          duration: 1.5,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "reverse" // Fix the "jump" by reversing smoothly
        }}
      />

        <p className="fw-bold mt-3">Interview Call with Peter</p>
        <div className="d-flex align-items-center justify-content-center my-4">
          <div className="position-relative d-flex align-items-center justify-content-center rounded-circle border border-primary" style={{ width: "105px", height: "105px",  marginTop: '10vh' }}>
            <span className="fw-semibold fs-4" style={{ color: 'black'}}>{formatTime(time)}</span>
          </div>
        </div>
        <p className="fw-bold mb-3" style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{transcript || "3 minutes call with our AI Tutor to assess your SQL skills and identify growth areas."}</p>
        {!hasStarted && (
          <button onClick={startConversation} className="btn fw-bold rounded-pill px-4 mt-5 shadow" style={{backgroundColor: '#2ecc71', color: 'white'}}>
            Start Call
          </button>
        )}
        {hasStarted && (
          <button style={{border: 'none', backgroundColor: 'transparent'}} onClick={stopConversation}>
            <img src="/static/end-call-icon.png" alt="Tutor Avatar" className="rounded-circle" style={{ width: "50px", height: "50px"}} />
          </button>
        )}
      </div>
    </div>
    </>
  );
};

export default VoiceAssistant;
